%title {
  font-family: var(--font-title);
}

h1,
%title-extra-large,
.title-extra-large {
  @extend %title;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

h2,
.title-large,
%title-large {
  @extend %title;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 20px;
}

h3,
%title-medium,
.title-medium {
  @extend %title;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.2px;
  line-height: 24px;
}

h4,
%title-small,
.title-small {
  @extend %title;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 0.2px;
  line-height: 1.125rem;
}

h5,
%title-very-small,
.title-very-small {
  @extend %title;
  font-size: $font-size-tiny;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 1rem;
}

%text {
  font-family: var(--font-body);
}

%text-extra-large,
.text-extra-large {
  @extend %text;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 24px;
}

%text-large,
.text-large {
  @extend %text;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 20px;
}

%text-medium,
.text-medium {
  @extend %text;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 1.125rem;
}

%text-small,
.text-small {
  @extend %text;
  font-size: $font-size-tiny;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 1rem;
}

%text-very-small,
.text-very-small {
  @extend %text;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 0.875rem;
}

p,
span,
a {
  @extend %text-medium;
}

a {
  color: currentColor;
  cursor: pointer;

  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      text-decoration: underline;
    }
  }

  .hack-custom-link & {
    border-bottom: 1px dotted currentColor;
    font-weight: 900;
    padding-bottom: 3px;
  }
}

.hack-custom-link {
  appearance: none;
  background: none;
  border: 0;
  border-bottom: 1px dotted currentColor;
  color: currentColor;
  cursor: pointer;
  font-weight: 900;
  padding: 0;
  padding-bottom: 3px;
}

.title-style--a {
  @extend %title-large;
  border-bottom: solid 1px var(--border-color-secondary);
  color: var(--text-color-primary);
  line-height: 40px;
  padding: $vr * 2 0;
}
