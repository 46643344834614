@import '../../styles/_00-settings/variables.scss';

.join-room-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 420px;
}

.join-room-user-row {
  align-items: center;
  border-color: #ecedf8;
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  flex-direction: row;
  height: 74px;
  justify-content: space-between;
  width: 100%;
}

.label-cell {
  align-items: center;
  color: #6e7090;
  display: flex;
  flex-grow: 1;
  font-family: 'lato';
  font-size: $font-size-small;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin-left: 16px;
  margin-right: 16px;
}

.join-room-cell {
  border-bottom: 1px dashed #1b1f3b;
  color: #1b1f3b;
  font-family: 'lato';
  font-size: $font-size-small;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  margin-right: 8px;
}

.avatar-cell {
  border-radius: 1000px;
  height: 40px;
  width: 40px;
}

.join-room-alternate-row {
  align-items: center;
  border-color: #ecedf8;
  border-style: solid;
  border-width: 0 0 1px;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: space-between;
  width: 420px;
}

.alternate-cell {
  align-items: center;
  color: #6e7090;
  display: flex;
  font-family: 'lato';
  font-size: $font-size-tiny;
  font-style: normal;
  font-weight: 700;
  height: 26px;
  line-height: 18px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.lato-bold {
  color: #1b1f3b;
  font-family: 'lato';
  font-size: $font-size-tiny;
  font-style: bold;
  font-weight: 700;
  line-height: 18px;
}
