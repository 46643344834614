@font-face {
  font-family: 'lato';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/lato/Lato-Black.woff') format('woff');
}

@font-face {
  font-family: 'lato';
  font-style: italic;
  font-weight: 900;
  src: url('../../assets/fonts/lato/Lato-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/lato/Lato-Bold.woff') format('woff');
}

@font-face {
  font-family: 'lato';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/lato/Lato-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/lato/Lato-Regular.woff') format('woff');
}

@font-face {
  font-family: 'lato';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/lato/Lato-Italic.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 200;
  src: url('../../assets/fonts/mulish/Mulish-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/mulish/Mulish-Light.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/mulish/Mulish-Regular.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/mulish/Mulish-Medium.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/mulish/Mulish-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/mulish/Mulish-Bold.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 800;
  src: url('../../assets/fonts/mulish/Mulish-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'mulish';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/mulish/Mulish-Black.woff') format('woff');
}

@font-face {
  font-family: 'Nanum Pen Script';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/nanumPenScript/NanumPenScript-Regular.woff')
    format('woff');
}
