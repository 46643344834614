@use 'sass:math';

@import 'variablesDarkTheme.scss';

// colours
$magenta-1: #6800d2;
$magenta-2: #a12bff;
$magenta-3: #7653f3;
$magenta-4: #5939cd;
$red-1: #ce1458;
$red-2: #ed2d74;
$red-3: #df0080;
$dark-grey-1: #1b1f3b;
$dark-grey-2: #3e425b;
$dark-grey-3: #616487;
$light-grey-1: #cacee9;
$light-grey-2: #ecedf8;
$light-grey-3: #f7f8fd;
$light-grey-4: #f2f3fb;
$teal: #33bfbc;
$green: #92e4b7;
$yellow: #fce300;

$white: #fff;
$black: #000;
$bg-color-a: #ccc;
$bg-color-b: #7d7d7d;
$color-text: #333;

// dimensions
$layout-max-width: 1440px;
$vr: 0.5rem; // vertical rhythm
$ggw: 1.5rem; // grid gutter width
$col-width: 6.125rem;
$cols: 12;
$layout-width: ($col-width * $cols) + ($ggw * $cols);
$layout-width-inner: $layout-width + $ggw;
$vm-xs: $vr * 0.5; // vertical margin extra small
$vm-s: $vr; // vertical margin small
$vm-m: $vr * 1.5; // vertical margin medium
$vm-l: $vr * 2.5; // vertical margin large

// typography
$sans-serif: 'lato', sans-serif;
$serif: 'Georgia Pro', Georgia, Times, serif;
$mono: 'Anonymous Pro', 'Droid Sans Mono', 'DejaVu Sans Mono', Monaco, Consolas,
  'Lucida Console', Courier, monospace;
$font-base: $sans-serif;
$title-font: 'mulish', sans-serif;
$penscript-font: 'Nanum Pen Script', cursive;

// Font Sizing
$font-size-tiny: 0.75rem; // 12px
$font-size-small: 0.875rem; // 14px
$font-size-normal: 1rem; // 16px
$font-size-large: 1.125rem; // 18px

$font-weight-black: 900;
$font-weight-xbold: 800;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 200;
$font-weight-ultra-thin: 100;

// Media Query Break Points (px & em)
$bpa-px: 320px;
$bpa-em: math.div(320, 16);

$bpb-px: 480px;
$bpb-em: math.div(480, 16);

$bpc-px: 660px;
$bpc-em: math.div(660, 16);

$bpd-px: 768px;
$bpd-em: math.div(768, 16);

$bpe-px: 1024px;
$bpe-em: math.div(1024, 16);

$bpf-px: 1220px;
$bpf-em: math.div(1220, 16);

$bpg-px: 1480px;
$bpg-em: math.div(1480, 16);

$bph-px: 1920px;
$bph-em: math.div(1920, 16);

$bplayout-px: 1490px;
$bplayout-em: math.div(1490, 16);
