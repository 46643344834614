@import '../_00-settings/variables.scss';

button {
  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }
}

.btn {
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0;
  width: auto;

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }
}

.btn[disabled],
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn--primary {
  --btn-primary-bg: #{$magenta-3};
  --btn-primary-color: #{$white};
  --btn-primary-radius: 28px;
  --btn-primary-min-width: 304px;
  --btn-primary-min-height: 44px;
  --btn-primary-padding-v: 9px;
  --btn-primary-padding-h: 16px;
  --btn-primary-border: 0;
  --btn-primary-bg-hover: #{$magenta-4};
  --btn-primary-color-hover: #{$white};
  --btn-primary-border-hover: none;
  --btn-primary-font: #{$title-font};
  --btn-primary-font-size: 0.875rem;
  --btn-primary-font-weight: 800;
  align-items: center;
  background-color: var(--btn-primary-bg);
  border: var(--btn-primary-border);
  border-radius: var(--btn-primary-radius);
  color: var(--btn-primary-color);
  display: flex;
  flex-direction: row;
  font-family: var(--btn-primary-font);
  font-size: var(--btn-primary-font-size);
  font-weight: var(--btn-primary-font-weight);
  justify-content: center;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: var(--btn-primary-bg-hover);

    &:disabled {
      background-color: var(--btn-primary-bg);
    }
  }

  &:focus {
    outline: dotted 1px;
  }

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }

  // &:disabled {
  //   opacity: 0.7;
  // }
}

.btn--secondary {
  --btn-secondary-bg: #{$light-grey-2};
  --btn-secondary-color: #{$dark-grey-2};
  --btn-secondary-radius: 28px;
  --btn-secondary-min-width: 304px;
  --btn-secondary-min-height: 44px;
  --btn-secondary-padding-v: 12px;
  --btn-secondary-padding-h: 12px;
  --btn-secondary-border: 0;
  --btn-secondary-bg-hover: #{$light-grey-1};
  --btn-secondary-color-hover: #{$white};
  --btn-secondary-border-hover: none;
  --btn-secondary-font: #{$title-font};
  --btn-secondary-font-size: 1rem;
  --btn-secondary-font-weight: 700;
  background-color: var(--btn-secondary-bg);
  border: var(--btn-secondary-border);
  border-radius: var(--btn-secondary-radius);
  color: var(--btn-secondary-color);
  font-family: var(--btn-secondary-font);
  font-size: var(--btn-secondary-font-size);
  font-weight: var(--btn-secondary-font-weight);
  min-height: var(--btn-secondary-min-height);
  min-width: var(--btn-secondary-min-width);
  padding: var(--btn-secondary-padding-v) var(--btn-secondary-padding-h);

  &:hover {
    background-color: var(--btn-secondary-bg-hover);

    &:disabled {
      background-color: var(--btn-secondary-bg);
    }
  }

  &:focus {
    outline: dotted 1px;
  }

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }

  // &:disabled {
  //   opacity: 0.7;
  // }
}

.btn--nav {
  --btn-nav-bg: #{$white};
  --btn-nav-color: #{$dark-grey-2};
  --btn-nav-radius: 6px;
  --btn-nav-min-width: 44px;
  --btn-nav-min-height: 44px;
  --btn-nav-padding-v: 12px;
  --btn-nav-padding-h: 12px;
  --btn-nav-border: 0;
  --btn-nav-bg-hover: #{$light-grey-2};
  --btn-nav-color-hover: #{$dark-grey-2};
  --btn-nav-border-hover: none;
  --btn-nav-font: #{$title-font};
  --btn-nav-font-size: 0.875rem;
  --btn-nav-font-weight: 700;

  align-items: center;
  background-color: var(--btn-nav-bg);
  border: var(--btn-nav-border);
  border-radius: var(--btn-nav-radius);
  color: var(--btn-nav-color);

  display: flex;
  font-family: var(--btn-nav-font);
  font-size: var(--btn-nav-font-size);
  font-weight: var(--btn-nav-font-weight);
  justify-content: center;
  min-height: var(--btn-nav-min-height);
  min-width: var(--btn-nav-min-width);
  padding: var(--btn-nav-padding-v) var(--btn-nav-padding-h);

  [data-theme-style='dark'] & {
    --btn-nav-bg: transparent;
    --btn-nav-color: #{$white};
    --btn-nav-bg-hover: transparent;
    --btn-nav-color-hover: #{$white};
  }

  &:hover,
  &.active {
    background-color: var(--btn-nav-bg-hover);
  }

  &:focus {
    outline: dotted 1px;
  }

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }

  svg {
    height: 20px;
    width: 20px;
  }

  .btn__text {
    font-family: var(--btn-nav-font);
    font-size: var(--btn-nav-font-size);
    font-weight: var(--btn-nav-font-weight);
    margin-left: 10px;
  }
}

.btn--action {
  --btn-action-bg: #{$light-grey-2};
  --btn-action-color: #{$dark-grey-2};
  --btn-action-radius: 6px;
  --btn-action-min-width: 44px;
  --btn-action-min-height: 44px;
  --btn-action-padding-v: 12px;
  --btn-action-padding-h: 12px;
  --btn-action-border: 0;
  --btn-action-bg-hover: #{$light-grey-1};
  --btn-action-color-hover: #{$dark-grey-2};
  --btn-action-border-hover: none;
  --btn-action-font: #{$title-font};
  --btn-action-font-size: 0.875rem;
  --btn-action-font-weight: 700;
  align-items: center;
  background-color: var(--btn-action-bg);
  border: var(--btn-action-border);
  border-radius: var(--btn-action-radius);
  color: var(--btn-action-color);

  display: flex;
  font-family: var(--btn-action-font);
  font-size: var(--btn-action-font-size);
  font-weight: var(--btn-action-font-weight);
  justify-content: center;
  min-height: var(--btn-action-min-height);
  min-width: var(--btn-action-min-width);
  padding: var(--btn-action-padding-v) var(--btn-action-padding-h);

  [data-theme-style='dark'] & {
    --btn-action-bg: #151935;
    --btn-action-color: #{$white};
    --btn-action-bg-hover: #151935;
    --btn-action-color-hover: #{$white};
  }

  &:hover,
  &.active {
    background-color: var(--btn-action-bg-hover);
  }

  &:focus {
    outline: dotted 1px;
  }

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }

  svg {
    height: 20px;
    width: 20px;
  }

  .btn__text {
    font-family: var(--btn-action-font);
    font-size: var(--btn-action-font-size);
    font-weight: var(--btn-action-font-weight);
    margin-left: 10px;
  }
}

.btn--option {
  --btn-option-bg: #{$light-grey-2};
  --btn-option-color: #{$dark-grey-1};
  --btn-option-bg-hover: #{darken($light-grey-2, 5%)};
  --btn-option-color-hover: #{$dark-grey-1};
  align-items: center;

  background-color: var(--btn-option-bg);
  border-radius: 6px;
  color: var(--btn-option-color);

  display: flex;
  font-family: var(--font-title);
  font-size: 0.875rem;
  font-weight: 800;
  justify-content: center;
  min-width: 120px;
  padding: $vr * 2 $ggw;

  &:hover,
  &.active {
    background-color: var(--btn-option-bg-hover);
  }

  &:focus {
    outline: dotted 1px;
  }

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }
}

.btn--prompt {
  --btn-prompt-bg: #{$red-3};
  --btn-prompt-color: #{$white};
  --btn-prompt-bg-hover: #{darken($red-3, 5%)};
  --btn-prompt-color-hover: #{$white};
  align-items: center;

  background-color: var(--btn-prompt-bg);
  border-radius: 6px;
  color: var(--btn-prompt-color);

  display: flex;
  font-family: var(--font-title);
  font-size: 0.875rem;
  font-weight: 800;
  justify-content: center;
  min-width: 120px;
  padding: $vr * 2 $ggw;

  &:hover,
  &.active {
    background-color: var(--btn-prompt-bg-hover);
  }

  &:focus {
    outline: dotted 1px;
  }

  &:focus:not(.focus-visible) {
    .js-focus-visible & {
      outline: none;
    }
  }
}

.btn--unstyled,
%btn--unstyled {
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.btn--tab {
  --btn-tab-bg: #{$white};
  --btn-tab-border: #{$light-grey-2};
  align-items: center;

  background-color: var(--btn-tab-bg);
  border-left: 1px solid var(--btn-tab-border);
  cursor: pointer;
  display: flex;
  height: 100%;
  min-width: 0;
  padding: 0 12px;

  [data-theme-style='dark'] & {
    --btn-tab-bg: #{$dark-magenta-5};
    --btn-tab-border: #{$dark-magenta-6};

    .close-tab {
      color: #{$white};
    }
  }

  &:last-of-type {
    border-right: 1px solid $light-grey-2;

    [data-theme-style='dark'] & {
      border-color: #{$dark-magenta-6};
    }
  }

  &--active {
    background-color: $magenta-3;
    color: $white;
    pointer-events: none;

    svg {
      color: $white;
    }

    .btn {
      pointer-events: all;
    }
  }

  svg {
    height: 12px;
    width: 12px;
  }

  .btn {
    align-items: center;
    display: flex;
  }
}

.btn--with-spinner {
  position: relative;
}
