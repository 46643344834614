@import '../../styles/_00-settings/variables.scss';

.download-container {
  align-items: center;
  background: #fff;
  border: 1px solid #cacee9;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 72px;
  justify-content: space-between;
  padding: 1rem 1.125rem;
  width: 420px;
}

.icon-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.text-container {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  top: 1rem;
}

.text-container__p--bold {
  color: #1b1f3b;
  font-family: Lato;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: 700;
  font-weight: bold;
  line-height: 1.375rem;
  margin: 0;
}

.text-container__p--subtitle {
  color: #6e7090;
  font-family: Lato;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  margin: 0;
}
