@import '../../styles/_00-settings/variables.scss';
@import '../../styles/_03-elements/type.scss';
@import '../../styles/_03-elements/buttons.scss';

$avatar-size-small: 1.5rem;
$avatar-size: 2.25rem;
$avatar-size-large: 2.75rem;
$avatar-size-xlarge: 4rem;

button.avatar {
  @extend %btn--unstyled;

  &:hover,
  &:focus {
    .avatar__initials {
      border: solid 2px currentColor;
    }
  }

  &:focus:not(:global(.focus-visible)) {
    .avatar__initials {
      :global(.js-focus-visible) & {
        border: 0;
      }
    }
  }
}

// the avatar__name will only show if we've passed the prop for it
// so will want to show it on hover regardless of if it's a btn or not
.avatar {
  &:hover,
  &:focus {
    .avatar__name {
      display: block;
    }
  }
}

button.avatar,
.avatar {
  --avatar-img-bg: #{$light-grey-2};
  --avatar-border-color: #{$white};
  --avatar-color-a: #{$dark-grey-2};
  --avatar-color-b: #3d68d1;
  --avatar-color-c: #1a1090;
  --avatar-color-d: #4d10ec;
  --avatar-color-e: #6e00d8;
  --avatar-color-f: #a705d8;
  --avatar-color-g: #c513a9;
  --avatar-color-h: #d31a4f;

  background-color: var(--avatar-img-bg);
  border-radius: 50%;
  height: $avatar-size;
  max-width: $avatar-size;
  min-width: $avatar-size;
  position: relative;

  &.avatar--small {
    height: $avatar-size-small;
    max-width: $avatar-size-small;
    min-width: $avatar-size-small;

    .avatar__initials {
      font-size: 0.5625rem;
    }
  }

  &.avatar--large {
    height: $avatar-size-large;
    max-width: $avatar-size-large;
    min-width: $avatar-size-large;
  }

  &.avatar--extra-large {
    height: $avatar-size-xlarge;
    max-width: $avatar-size-xlarge;
    min-width: $avatar-size-xlarge;
  }

  img {
    vertical-align: top;
  }

  .avatar__initials,
  .avatar__icon {
    @extend %title-small;
    align-items: center;
    border: solid 2px var(--avatar-border-color);
    border-radius: 50%;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.avatar__icon {
  color: var(--avatar-color-a);

  svg {
    width: 22px;
  }

  .avatar--no-border & {
    border: 0;
  }
}

.avatar__initials {
  .avatar--colour-a & {
    color: var(--avatar-color-a);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-a);
      border-color: var(--avatar-color-a);
      color: $white;
    }
  }

  .avatar--colour-b & {
    color: var(--avatar-color-b);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-b);
      border-color: var(--avatar-color-b);
      color: $white;
    }
  }

  .avatar--colour-c & {
    color: var(--avatar-color-c);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-c);
      border-color: var(--avatar-color-c);
      color: $white;
    }
  }

  .avatar--colour-d & {
    color: var(--avatar-color-d);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-d);
      border-color: var(--avatar-color-d);
      color: $white;
    }
  }

  .avatar--colour-e & {
    color: var(--avatar-color-e);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-e);
      border-color: var(--avatar-color-e);
      color: $white;
    }
  }

  .avatar--colour-f & {
    color: var(--avatar-color-f);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-f);
      border-color: var(--avatar-color-f);
      color: $white;
    }
  }

  .avatar--colour-g & {
    color: var(--avatar-color-g);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-g);
      border-color: var(--avatar-color-g);
      color: $white;
    }
  }

  .avatar--colour-h & {
    color: var(--avatar-color-h);

    [data-theme-style='dark'] & {
      background-color: var(--avatar-color-h);
      border-color: var(--avatar-color-h);
      color: $white;
    }
  }

  .avatar--extra-large & {
    font-size: 1.375rem;
  }

  .avatar--no-border & {
    border: 0;
  }
}

.avatar__presence {
  position: absolute;
  right: -2px;
  top: -4px;
  z-index: 1;
}

.avatar__name {
  --avatar-name-color: #{$white};
  background-color: var(--avatar--color-a);
  border-radius: 4px;
  bottom: calc(100% + 0.625rem);
  color: var(--avatar-name-color);
  display: none;
  left: 50%;
  padding: 0.5rem;
  position: absolute;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;

  // Triangle
  &::after {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 8px solid;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 0;
  }

  .avatar--colour-a & {
    background-color: var(--avatar-color-a);

    &::after {
      border-top-color: var(--avatar-color-a);
    }
  }

  .avatar--colour-b & {
    background-color: var(--avatar-color-b);

    &::after {
      border-top-color: var(--avatar-color-b);
    }
  }

  .avatar--colour-c & {
    background-color: var(--avatar-color-c);

    &::after {
      border-top-color: var(--avatar-color-c);
    }
  }

  .avatar--colour-d & {
    background-color: var(--avatar-color-d);

    &::after {
      border-top-color: var(--avatar-color-d);
    }
  }

  .avatar--colour-e & {
    background-color: var(--avatar-color-e);

    &::after {
      border-top-color: var(--avatar-color-e);
    }
  }

  .avatar--colour-f & {
    background-color: var(--avatar-color-f);

    &::after {
      border-top-color: var(--avatar-color-f);
    }
  }

  .avatar--colour-g & {
    background-color: var(--avatar-color-g);

    &::after {
      border-top-color: var(--avatar-color-g);
    }
  }

  .avatar--colour-h & {
    background-color: var(--avatar-color-h);

    &::after {
      border-top-color: var(--avatar-color-h);
    }
  }
}

.avatar__img {
  border: solid 2px var(--avatar-border-color);
  border-radius: 50%;
  overflow: hidden;
}
