@import 'styles/_00-settings/variables.scss';
@import 'styles/_03-elements/type.scss';
@import 'styles/_03-elements/fonts.scss';

.layout {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-layout {
  --login-layout-bg: #{$light-grey-3};
  background-color: var(--login-layout-bg);
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.main-secondary {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.main__figure {
  bottom: 50px;
  margin: 10px;
  max-width: 404px;
  position: absolute;
  right: 50px;

  img {
    width: 100%;
  }
}

.main-container {
  --login-container-bg: #{$white};
  align-items: center;
  background-color: var(--login-container-bg);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $vr * 8 $ggw * 1.5 $vr * 5;
  transition: all 300ms ease;
  width: calc(var(--layout-sidebar-width) + var(--layout-action-bar-width));
}

// .main-container__header {
//   margin-bottom: $vr * 2;
//   padding: 0 $ggw;
// }

.main-container__title {
  --login-title-color: var(--color-brand-3);
  @extend %title-extra-large;
  color: var(--login-title-color);
  font-weight: 900;
  margin-bottom: $vr * 2;
}

.main-container__intro {
  font-family: 'mulish';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
  margin-top: 40px;
}

.main-container__body {
  height: 100%;
  margin-top: 40px;
}
